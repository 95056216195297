import axios from "axios"
import Vue from "vue";
import {notification} from "ant-design-vue";
import moment from "moment";
import _ from "lodash";

const arrayToCommaStringReducer = (previousValue, currentValue) =>
    previousValue + "," + currentValue;

const state = {
    measurementSites: {},
    measurementSitesLoading: false,
    measurementNetworks: {},
    selectedMeasurementNetworkID: "",
    measurementNetworkIDs: {},
    networkPermissions: [],
    siteVariables: {},
    allVariables: [],
    thresholdTypesDict: {},
    thresholdTypesList: {},
    aggregationLevels: [],
    seriesLogActions: [],
    timeSeriesCorrectionTypes: [],
};

const ALLOWED_QUALITY_CONTROL_LEVELS = ['q00', 'q01', 'q02', 'highest'];

const getters = {
    measurementSitesLoading(state) {
        return state.measurementSitesLoading;
    },
    measurementSites(state, getters) {
        if (state.measurementSites[getters.sinappsAppID]) {
            state.measurementSites[getters.sinappsAppID].sort(function (a, b) {
                var dateB = new Date(b.created_at);
                var dateA = new Date(a.created_at);
                if (dateB < dateA) {
                    return -1;
                }
                if (dateB > dateA) {
                    return 1;
                }
                return 0;
            });
            return state.measurementSites[getters.sinappsAppID];
        } else {
            return [];
        }
    },
    measurementNetworkIDs(state, getters) {
        if (state.measurementNetworkIDs[getters.sinappsAppID]) {
            return state.measurementNetworkIDs[getters.sinappsAppID];
        } else {
            return getters.meetnetAuthPayload.measurementnetworks;
        }
    },
    selectedMeasurementNetworkID() {
        return state.selectedMeasurementNetworkID;
    },
    measurementNetworks(state, getters) {
        if (state.measurementNetworks[getters.sinappsAppID]) {
            return state.measurementNetworks[getters.sinappsAppID];
        } else {
            return [];
        }
    },
    networkPermissions(state) {
        return state.networkPermissions;
    },
    siteVariables(state, getters) {
        return state.siteVariables[getters.sinappsAppID];
    },
    allVariables(state) {
        return state.allVariables;
    },
    thresholdTypesDict(state) {
        return state.thresholdTypesDict;
    },
    thresholdTypesList(state) {
        return state.thresholdTypesList;
    },
    aggregationLevels(state) {
        return state.aggregationLevels;
    },
    seriesLogActions(state) {
        return state.seriesLogActions;
    },
    timeSeriesCorrectionTypes(state) {
        return state.timeSeriesCorrectionTypes;
    },
};

const mutations = {
    setMeasurementSitesLoading(state, value) {
        state.measurementSitesLoading = value;
    },
    clearMeasurementSites(state, sinappsAppID) {
        delete state.measurementSites[sinappsAppID];
    },
    setMeasurementNetworkIDs(state, payload) {
        Vue.set(state.measurementNetworkIDs, payload.sinappsAppID, payload.data);
    },
    setMeasurementNetworks(state, payload) {
        Vue.set(state.measurementNetworks, payload.sinappsAppID, payload.data);
    },
    setSelectedMeasurementNetworkID(state, id) {
        state.selectedMeasurementNetworkID = id;
    },
    setNetworkPermissions(state, payload) {
        state.networkPermissions = payload;
    },
    appendMeasurementSites(state, payload) {
        const sites = payload.data;
        let existingSites = state.measurementSites[payload.sinappsAppID] || [];
        Vue.set(state.measurementSites, payload.sinappsAppID, [
            ...existingSites,
            ...sites,
        ]);
    },
    appendNetworkSiteVariables(state, payload) {
        let variables = state.siteVariables[payload.sinappsAppID] || [];
        payload.data.forEach((variable) => {
            if (!variables.find((el) => el.oid == variable.oid)) {
                variables.push(variable);
            }
        });
        Vue.set(state.siteVariables, payload.sinappsAppID, variables);
    },
    setAllVariables(state, payload) {
        state.allVariables = payload;
    },
    setThresholdTypesDict(state, payload) {
        state.thresholdTypesDict = payload;
    },
    setThresholdTypesList(state, payload) {
        state.thresholdTypesList = payload;
    },
    setAggregationLevels(state, payload) {
        state.aggregationLevels = payload;
    },
    setSeriesLogActions(state, payload) {
        state.seriesLogActions = payload;
    },
    setTimeSeriesCorrectionTypes(state, payload) {
        state.timeSeriesCorrectionTypes = payload;
    },
};

const actions = {
    getMeasurementNetwork(context, oid) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementnetwork/${oid}/`,
        });
    },
    getMeasurementNetworks(context) {
        console.info('Fetching measurement networks')
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementnetwork/`,
        });
    },
    setSelectedMeasurementNetworkID(context, id) {
        context.commit("setSelectedMeasurementNetworkID", id);
    },
    getMeasurementNetworkPermissions(context) {
        console.info('Fetching measurement network permissions')
        axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementnetwork/listPermissions/`,
        })
            .then((response) => {
                context.commit("setNetworkPermissions", response.data);
            })
            .catch((error) => {
                console.log("getNetworkPermissions error", error);
            });
    },
    searchMeasurementSites({commit, dispatch, getters}, query) {
        const sinappsAppID = getters.sinappsAppID;
        commit("clearMeasurementSites", sinappsAppID);
        commit("setMeasurementSitesLoading", true);

        dispatch("searchMeasurementSitesByNetwork", {
            networkIDs: getters.measurementNetworkIDs.reduce(
                arrayToCommaStringReducer
            ),
            query,
        })
            .then((response) => {
                if (response) {
                    commit("appendMeasurementSites", {
                        data: response.data,
                        sinappsAppID,
                    });
                    commit("setMeasurementSitesLoading", false);
                } else {
                    commit("setMeasurementSitesLoading", false);
                    notification.error({
                        message: `Something went wrong while searching for the measurement sites...`,
                        description: response,
                    });
                }
            })
            .catch((error) => {
                commit("setMeasurementSitesLoading", false);
                notification.error({
                    message: `Something went wrong while searching the measurement sites...`,
                    description: error,
                });
            });
    },
    getMeasurementSites({dispatch, commit, getters}) {
        let measurementNetworkIDs = getters.measurementNetworkIDs;
        const sinappsAppID = getters.sinappsAppID;
        commit("clearMeasurementSites", sinappsAppID);
        commit("setMeasurementSitesLoading", true);

        dispatch(
            "getMeasurementSitesByNetworks",
            measurementNetworkIDs.reduce(arrayToCommaStringReducer)
        )
            .then((response) => {
                if (response) {
                    commit("appendMeasurementSites", {
                        data: response.data,
                        sinappsAppID,
                    });
                    commit("setMeasurementSitesLoading", false);
                } else {
                    commit("setMeasurementSitesLoading", false);
                    notification.error({
                        message: `Something went wrong while fetching the measurement sites...`,
                        description: response,
                    });
                }
            })
            .catch((error) => {
                commit("setMeasurementSitesLoading", false);
                notification.error({
                    message: `Something went wrong while fetching the measurement sites...`,
                    description: error,
                });
            });
    },
    fetchNetworkSiteVariables(context, oid) {
        const sinappsAppID = context.getters.sinappsAppID;
        return context
            .dispatch("getNetworkVariables", oid)
            .then((response) => {
                context.commit("appendNetworkSiteVariables", {
                    data: response.data,
                    sinappsAppID,
                });
            })
            .catch((error) => {
                console.log("getNetworkVariables Error", error);
            });
    },
    getMeasurementSitesByNetworks(context, networkIDs) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/?network__in=${networkIDs}`,
        });
    },

    async getMeasurementSitesByNetworksEncapsulated(context, networkIdArray) {
        if (!Array.isArray(networkIdArray)) {
            throw new Error(
                `Argument networkIDs must be an array but was ${networkIdArray}`
            );
        }

        let response;
        try {
            response = await context.dispatch(
                "getMeasurementSitesByNetworks",
                networkIdArray
            );
        } catch (error) {
            throw new Error(
                `Unable to retrieve measurement sites by networks due to unexpected error: ${error.stack}`
            );
        }

        if (response.status !== 200) {
            throw new Error(
                `Unable to retrieve measurement sites by networks ${networkIDs} due to unexpected response status code ${response.status}`
            );
        }

        return response.data;
    },

    getNetworksMeasurementSites(context, id) {
        console.info(`Fetching measurement sites for network ${id}`)
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementnetwork/${id}/get_measurement_sites/`,
        });
    },
    searchMeasurementSitesByNetwork(context, {networkIDs, query}) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/?network__in=${networkIDs}&search=${query}`,
        });
    },
    getMeasurementSite(context, id) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/`,
        });
    },
    deleteMeasurementSite(context, id) {
        return axios({
            method: "DELETE",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/`,
        });
    },
    getSiteSensorsList(context, id) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/get_sensors/`,
        });
    },
    getVariableChoiceFields(context) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/variable/choice_fields/`,
        });
    },
    getNetworkVariables(context, id) {
        console.info(`Fetching all variables for network ${id}`)
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementnetwork/${id}/get_variables/`,
        });
    },

    getSiteVariables(context, id, onlyManual = false) {
        const queryParameters = new URLSearchParams();
        onlyManual && queryParameters.set('only_manual', 'true')
        const queryString = [...queryParameters.entries()].length > 0 ? `?${queryParameters}` : '';
        const url = `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/get_variables/${queryString}`;
        return axios.get(url);
    },

    /**
     * Retrieves the variables metadata for all variables of the given site.
     * This function is pure, i.e. it does not have any site effects like storing data in a cache.
     * @param context
     * @param {number} siteId The oid of the site.
     * @returns {Promise<Array<Object>>} An array of objects containing various metadata about the variables present for this site.
     * @throws Whenever something goes wrong, invalid parameter passed, unexpected response status code or failed HTTP call.
     */
    async getSiteVariablesEncapsulated(context, siteId) {
        if (typeof siteId !== "number") {
            throw new Error(`Argument 'id' is required but was '${siteId}'`);
        }

        let response;
        try {
            response = await actions.getSiteVariables(context, siteId);
        } catch (error) {
            throw new Error(`Unable to get variables for site '${siteId}' due to ${error.message} with body '${error.response?.data}'`);
        }

        if (response.status !== 200) {
            throw new Error(`Unable to get data for site '${siteId}' due to unexpected response status code '${response.status}' with body '${response?.data}'`);
        }

        return response.data;
    },

    /**
     * Retrieves all variables marked for manual measurements of a given site.
     * @param context Vuex context.
     * @param id {number} Id of the site (also known as 'oid')
     * @returns {Promise<Object[]>} Promise of variables
     */
    async fetchManualMeasurementVariables(context, id) {
        console.info(`Fetching manual measurement variables for site ${id}`)
        const response = await axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/manual_measurement_variables/`,
        });

        const variables = response.data
        console.debug(`Fetched ${variables.length} manual measurement variables for site ${id}`, variables)
        return variables
    },

    /**
     * Adds variables enabled for manual measurement for a site.
     * @param context Vuex context
     * @param siteId {number}
     * @param variableIds {number[]}
     * @returns {Promise<?>}
     */
    async addManualMeasurementVariables(context, {siteId, variableIds}) {
        console.info(`Adding manual measurement variables [${variableIds}] for site ${siteId}`)
        const response = await axios({
            method: "POST",
            data: variableIds,
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${siteId}/manual_measurement_variables/`,
        });
        return response.data
    },

    /**
     * Removes variables enabled for manual measurement for a site.
     * @param context Vuex context
     * @param siteId {number}
     * @param variableIds {number[]}
     * @returns {Promise<?>}
     */
    async removeManualMeasurementVariables(context, {siteId, variableIds}) {
        console.info(`Removing manual measurement variables [${variableIds}] for site ${siteId}`)
        const response = await axios({
            method: "DELETE",
            data: variableIds,
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${siteId}/manual_measurement_variables/`,
        });
        return response.data
    },

    createVariable(context, payload) {
        return axios({
            method: "POST",
            data: payload,
            url: `${context.getters.meetnetAPIURL}/sensoren/variable/`,
        });
    },
    getVariables(context) {
        return axios({
            method: "GET",
            url: `${context.getters.meetnetAPIURL}/sensoren/variable/`,
        });
    },
    setAllVariables(context, payload) {
        context.commit("setAllVariables", payload);
    },
    deleteVariable(context, id) {
        return axios({
            method: "DELETE",
            url: `${context.getters.meetnetAPIURL}/sensoren/variable/${id}/`,
        });
    },
    createMeasurementSite(context, payload) {
        return axios({
            method: "POST",
            data: payload,
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/`,
        });
    },
    updateMeasurementSite(context, payload) {
        delete payload.image;
        return axios({
            method: "PATCH",
            data: payload,
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${payload.oid}/`,
        });
    },
    uploadSiteImage(context, {id, data, contentType = "multipart/form-data"}) {
        return axios({
            method: "PATCH",
            headers: {
                "Content-Type": contentType,
            },
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/`,
            data: data,
        });
    },
    async downloadSiteData(context, payload) {

        const method = "measurement_site_pks" in payload ? "getMultipleMeasurementSiteData" : "getMeasurementSiteData";
        const response = await context.dispatch(method, {
            ...payload,
            output_format: 'csv',
            response_type: 'blob'
        });

        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', 'timeseries.csv')
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    },
    exportSiteData(context, {id, from_datetime, to_datetime, variable}) {
        return axios({
            method: "POST",
            url:
                `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/post_data_to_external_sources/?` +
                _.join(
                    _.remove([
                        from_datetime ? `from_datetime=${from_datetime}` : "",
                        to_datetime ? `to_datetime=${to_datetime}` : "",
                        variable ? `variable=${encodeURIComponent(variable)}` : "",
                    ]),
                    "&"
                ),
        });
    },
    getMeasurementSiteData(context, {
        measurement_site_pk,
        aggregation_duration,
        from_datetime,
        to_datetime,
        qc_level,
        variable,
        variable__pk,
        include_raw_data,
        output_format,
        response_type = 'json'
    }) {
        console.debug(`Fetching data for measurement site ${measurement_site_pk}`)
        let url = `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${measurement_site_pk}/get_data/?` +
            _.join(
                _.remove([
                    from_datetime ? `from_datetime=${from_datetime}` : "",
                    to_datetime ? `to_datetime=${to_datetime}` : "",
                    qc_level ? `qc_level=${qc_level}` : "",
                    variable ? `variable=${encodeURIComponent(variable)}` : "",
                    variable__pk ? `variable__pk=${variable__pk}` : "",
                    aggregation_duration
                        ? `aggregation_level=${aggregation_duration}`
                        : "",
                    include_raw_data ? `include_raw_data=${include_raw_data}` : "",
                ]),
                "&"
            )

        if (output_format) {
            url += `&output_format=${output_format}`;
        }

        console.debug(`Invoking fetch call for measurement site data to url ${url}`)
        return axios.get(url,{
            responseType: response_type
        });
    },

    getMultipleMeasurementSiteData(context, {
        aggregation_duration,
        from_datetime,
        to_datetime,
        qc_level,
        variable,
        measurement_site_pks,
        include_raw_data,
        output_format,
        response_type = 'json'
    }) {
        let url = `${context.getters.meetnetAPIURL}/sensoren/measurementsite/get_data_at_multiple_sites/?` +
            _.join(
                _.remove([
                    from_datetime ? `from_datetime=${from_datetime}` : "",
                    to_datetime ? `to_datetime=${to_datetime}` : "",
                    qc_level ? `qc_level=${qc_level}` : "",
                    variable ? `variable=${variable}` : "",
                    measurement_site_pks
                        ? `measurement_site_pks=${measurement_site_pks}`
                        : "",
                    aggregation_duration
                        ? `aggregation_level=${aggregation_duration}`
                        : "",
                    include_raw_data ? `include_raw_data=${include_raw_data}` : "",
                ]),
                "&"
            )

        if (output_format) {
            url += `&output_format=${output_format}`;
        }

        return axios({
            method: "GET",
            url: url,
            responseType: response_type
        });
    },

    /**
     * Encapsulates the datasource for this API call. So that the component does not need to be aware of
     * datasource specific things, like the fact that it's a HTTP call fetched using Axios witch can fail with strange status codes etc...
     * This way, the call-site can be unaware of technical implementation details and focus on the data or the error.
     * @param context
     * @param parameters
     * @returns {Promise<any>}
     */
    async getMeasurementSiteDataEncapsulated(context, parameters) {
        const siteId = parameters.siteId;
        if (typeof siteId !== "number") {
            throw new Error(`Argument 'siteId' is required but was '${siteId}'`);
        }

        const nextParameters = {
            measurement_site_pk: parameters.siteId,
            from_datetime: parameters.from_datetime,
            to_datetime: parameters.to_datetime,
        };

        const qc_level = parameters.qc_level;
        if(qc_level) {
            if(!ALLOWED_QUALITY_CONTROL_LEVELS.includes(qc_level)) {
                throw new Error(`Optional argument 'qc_level' must be any of [${ALLOWED_QUALITY_CONTROL_LEVELS.join()}] but was '${qc_level}'`);
            }
            nextParameters.qc_level = qc_level;
        }

        // If variables are present, request only the specified variables, otherwise (for backward compatibility) query all of them (this can take some time though)
        const variableIds = parameters.variableIds;
        if (!Array.isArray(variableIds) || variableIds.find(v => typeof v !== 'number')) {
            throw new Error('Argument variableIds must be an array of  numbers')
        }
        nextParameters.variable__pk = variableIds.join();

        function validateDatetimeParameter(datetime) {
            if (!datetime) {
                return undefined;
            }
            const m = moment(datetime);
            if (!m.isValid()) {
                throw new Error("Datetime argument must be a valid date");
            }
            return m.toISOString();
        }

        nextParameters.from_datetime = validateDatetimeParameter(
            parameters.from_datetime
        );
        nextParameters.to_datetime = validateDatetimeParameter(
            parameters.to_datetime
        );

        try {
            console.warn(`Delegating to deprecated getMeasurementSiteData() using parameters`, nextParameters)
            const response = await actions.getMeasurementSiteData(context, nextParameters)
            return response.data;
        } catch (error) {
            throw new Error(`Unable to get meetnet data for site '${siteId}' due to: ${error.response.data}`, {cause: error})
        }
    },

    getMeasurementSiteStats(
        context,
        {id, from_datetime, to_datetime, qc_level, variable, aggregation_level}
    ) {
        return axios({
            method: "GET",
            url:
                `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/calculate_summary_stats/?` +
                _.join(
                    _.remove([
                        from_datetime ? `from_datetime=${from_datetime}` : "",
                        to_datetime ? `to_datetime=${to_datetime}` : "",
                        qc_level ? `qc_level=${qc_level}` : "",
                        variable ? `variable=${variable}` : "",
                        aggregation_level ? `aggregation_level=${aggregation_level}` : "",
                    ]),
                    "&"
                ),
        });
    },

    getSiteSensorInstallations(context, id) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/get_sensor_locations/`,
        });
    },
    getSiteSensorInstallation(context, id) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/sensorlocation/${id}/`,
        });
    },
    deleteSiteSensorInstallation(context, id) {
        return axios({
            method: "DELETE",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/sensorlocation/${id}/`,
        });
    },
    createSensorInstallation(context, payload) {
        let url = "";
        if (payload.force) {
            url = `${context.getters.meetnetAPIURL}/sensoren/sensorlocation/?close_active=true/`;
        } else {
            url = `${context.getters.meetnetAPIURL}/sensoren/sensorlocation/`;
        }
        delete payload.force;

        return axios({
            method: "POST",
            data: payload,
            url: url,
        });
    },

    editSensorInstallation(context, payload) {
        let url;
        if (payload.force) {
            url = `${context.getters.meetnetAPIURL}/sensoren/sensorlocation/${payload.oid}/?close_active=true/`;
        } else {
            url = `${context.getters.meetnetAPIURL}/sensoren/sensorlocation/${payload.oid}/`;
        }
        delete payload.force;

        return axios({
            method: "PATCH",
            data: payload,
            url: url,
        });
    },

    uploadSensorInstallationImage(context, {id, data, contentType = "multipart/form-data"}) {
        return axios({
            method: "PATCH",
            data: data,
            headers: {
                "Content-Type": contentType,
            },
            url: `${context.getters.meetnetAPIURL}/sensoren/sensorlocation/${id}/`,
        });
    },

    /*
    Site Thresholds
    */
    getthresholdTypesDict(context) {
        axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/threshold/threshold_types/`,
        })
            .then((response) => {
                let thresholdTypesDict = {};
                let thresholdTypesList = [];

                response.data.forEach((el) => {
                    Vue.set(thresholdTypesDict, el[0], el[1]);
                    thresholdTypesList.push({
                        text: el[1],
                        value: el[0],
                    });
                    return el;
                });
                context.commit("setThresholdTypesDict", thresholdTypesDict);
                context.commit("setThresholdTypesList", thresholdTypesList);
            })
            .catch((error) => {
                console.log("getthresholdTypesDict Error", error);
            });
    },

    createSiteThreshold(context, payload) {
        console.info('Creating measurement site threshold', payload)
        return axios({
            method: "POST",
            data: payload,
            url: `${context.getters.meetnetAPIURL}/sensoren/threshold/`,
        });
    },

    editSiteThreshold(context, payload) {
        return axios({
            method: "PATCH",
            data: payload,
            url: `${context.getters.meetnetAPIURL}/sensoren/threshold/${payload.oid}/`,
        });
    },
    deleteSiteThreshold(context, oid) {
        return axios({
            method: "DELETE",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/threshold/${oid}/`,
        });
    },

    /*
    Site manual validation
    */
    getTimeSeriesCorrectionTypes(context) {
        axios({
            method: "GET",
            url: `${context.getters.meetnetAPIURL}/sensoren/supported_timeseries_corrections`,
        })
            .then((response) => {
                context.commit("setTimeSeriesCorrectionTypes", response.data);
            })
            .catch((error) => {
                console.log(
                    "ERROR getTimeSeriesCorrectionTypes",
                    error,
                    Object.entries(error)
                );
            });
    },
    applyTimeSeriesCorrection(context, {siteID, data}) {
        return axios({
            method: "POST",
            data: data,
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${siteID}/apply_timeseries_correction/`,
        });
    },
    getSiteValidators(context, id) {
        return axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${id}/get_validators/`,
        });
    },
    saveSiteValidator(context, payload) {
        const siteID = payload.siteID;
        delete payload.siteID;

        console.info(`Updating site validators for measurementsite ${siteID} and payload`, payload)
        return axios({
            method: "POST",
            data: payload,
            url: `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${siteID}/setupValidators/`,
        });
    },
    deleteSiteValidators(context, oid) {
        return axios({
            method: "DELETE",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/validator/${oid}/`,
        });
    },

    getStatsAggregationLevels(context) {
        axios({
            method: "GET",
            data: {},
            url: `${context.getters.meetnetAPIURL}/sensoren/available_aggregations/`,
        })
            .then((response) => {
                context.commit("setAggregationLevels", response.data);
            })
            .catch((error) => {
                console.log("Error available_aggregations", error);
            });
    },
    get_return_periods(context, {site_id, from_datetime, to_datetime}) {
        return axios.get(
            `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${site_id}/calculate_return_periods/`,
            {params: {from_datetime, to_datetime}}
        );
    },
    getSeriesLogs(context, {siteID, action, variable}) {
        return axios({
            method: "GET",
            url:
                `${context.getters.meetnetAPIURL}/sensoren/measurementsite/${siteID}/get_manual_edit_log/?` +
                _.join(
                    _.remove([
                        action ? `action=${action}` : "",
                        variable ? `variable=${variable}` : "",
                    ]),
                    "&"
                ),
        });
    },
    getSeriesLogActions(context) {
        axios({
            method: "GET",
            url: `${context.getters.meetnetAPIURL}/logger/timeserieslog/get_actions/`,
        })
            .then((response) => {
                let actionsList = [];
                for (const [key, text] of Object.entries(response.data)) {
                    actionsList.push({text, key});
                }
                context.commit("setSeriesLogActions", actionsList);
            })
            .catch((error) => {
                console.log("getSeriesLogActions ERROR", error);
            });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
