import axios from "axios"
import _ from 'lodash';

const state = {
  mca_criteria_list: [],
  mca_domain_list: [],
  mca_export_plot_data: [],
  mca_export_plot_layout: [],
  mca_topic_list: [],
  mca_selected_topic: "",
};

const getters = {
  mca_selected_topic: (state) => {
    return state.mca_selected_topic;
  },
  mca_criteria_list: (state) => {
    return state.mca_criteria_list;
  },
  mca_domain_list: (state) => {
    return state.mca_domain_list;
  },
  mca_criteria_tree: (state, getters) => {
    let nodes = _.cloneDeep(state.mca_criteria_list);
    let parentindex;
    for (let el of nodes) {
      if (getters.mca_criteria_branches(el.oid).length > 0) {
        // criteria with children should have a children attribute (an array)
        el["children"] = [];
      }
    }
    // continue moving nodes till all remaining nodes are root nodes
    while (nodes.length > _.filter(nodes, { parent: null }).length) {
      let thisnode = nodes.pop();
      // check if a node has children, if not move it to its correct position
      // this prevents nodes who's parent has already moved and is no longer findable
      if (
        _.filter(nodes, (o) => {
          if (o.parent && o.parent.oid == thisnode.oid) return true;
          return false;
        }).length > 0
      ) {
        // this node still has children
        nodes.unshift(thisnode);
        continue;
      }
      if (thisnode.parent) {
        parentindex = _.findIndex(nodes, { oid: thisnode.parent.oid });
      } else {
        parentindex = -1;
      }
      if (parentindex == -1) {
        // no parent found, re-add
        nodes.unshift(thisnode);
      } // parent found
      else {
        nodes[parentindex]["children"].unshift(thisnode);
      }
    }
    return nodes;
  },
  mca_criteria_branches: (state) => (parentid) => {
    if (state.mca_criteria_list.length == 0) {
      return [];
    }
    if (parentid) {
      return _.filter(state.mca_criteria_list, function(o) {
        if (o.parent) {
          return o.parent.oid == parentid;
        } else {
          return false;
        }
      });
    } else {
      return _.filter(state.mca_criteria_list, {parent: null});
    }
  },
  mca_export_plot_data: (state) => {
    return state.mca_export_plot_data;
  },
  mca_export_plot_layout: (state) => {
    return state.mca_export_plot_layout;
  },
  mca_topic_list: (state) => {
    return state.mca_topic_list;
  }
};

const mutations = {
  set_mca_selected_topic(state, payload) {
    state.mca_selected_topic = payload;
  },
  append_to_mca_topic_list(state, item) {
    state.mca_topic_list.push(item);
  },
  set_mca_topic_list(state, payload) {
    state.mca_topic_list = payload;
  },
  set_mca_criteria_list(state, payload) {
    state.mca_criteria_list = payload;
  },
  set_mca_domains_list(state, payload) {
    state.mca_domain_list = _.sortBy(payload, [function(o) { return Number(o.name); }]);
  },
  set_mca_export_plot_data(state, payload) {
    state.mca_export_plot_data = payload;
  },
  set_mca_export_plot_layout(state, payload) {
    state.mca_export_plot_layout = payload;
  }
};

const actions = {
  set_mca_selected_topic: ({ commit }, payload) => {
    commit("set_mca_selected_topic", payload);
  },
  append_to_mca_topic_list: ({ commit }, payload) => {
    commit("append_to_mca_topic_list", payload);
  },
  get_mca_topic_list: ({state, commit})  => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        data: {},
        url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/topic`,
      })
        .then(response => {
          commit("set_mca_topic_list", response.data);
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting MCA topics list", error, Object.entries(error))
          reject(error);
        })
  })


  },
  mca_get_analysis: ({ state }) => {
    return axios({
      method: "GET",
      data: {},
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/analysis/?topic=${state.mca_selected_topic}`,
    });
  },
  mca_edit_analysis: ({}, params) => {
    console.info('Editing an existing analysis', params);

    return axios({
      method: "PATCH",
      data: params,
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/analysis/${params.oid}/`,
    });
  },
  mca_create_analysis: ({}, params) => {
    console.info('Creating a new analysis', params);

    return axios({
      method: "POST",
      data: params,
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/analysis/`,
    });
  },
  mca_delete_analysis: ({}, id) => {
    return axios({
      method: "DELETE",
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/analysis/${id}/`,
    });
  },
  mca_get_criteria: (context, analysisID) => {
    axios({
      method: "GET",
      data: {},
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/criterion/?analysis=${analysisID}`,
    }).then((response) => {
      context.commit("set_mca_criteria_list", response.data);
    })
    .catch((error) => {
      console.log("Error while fetching criteria.", error);
    });
  },
  mca_get_scores_for_analysis: ({}, params) => {
    return axios({
      method: "GET",
      data: {},
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/analysis/${params.id}/calculate_score/?pk_template=${params.template}`,
    });
  },
  mca_upload_solution_file(context, payload) {
    return axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/analysis/parse_file_with_scores/?pk_analysis=${payload.id}&filename=${payload.name}`,
      data: payload.data,
    });
  },
  mca_get_domains: async (context) => {
    try {
      const topic = context.state.mca_selected_topic;
      console.info(`Loading MCA domains for selected topic '%s'`, topic)
      const response = await axios({ method: "GET", data: {}, url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}mca/subject/?topic=${topic}`})
      const domains = response.data;
      context.commit("set_mca_domains_list", domains)
      console.debug(`Loaded MCA domains`, domains)
      return domains
    } catch(error) {
      console.error("Error while fetching domains.", error);
      throw new Error(`Unable to fetch domains (aka subjects) from the backend due to ${error.stack}`, {cause: error})
    }
  },

  /**
   * Loads MCA domains only if they have not already been loaded. Otherwise uses the cached version in the store.
   * You can use either the store getter or the return value to retrieve the values loaded.
   * @param context Vuex context.
   * @returns Same as `mca_domain_list` getter
   */
  ensureMCADomainList: async context => {
    console.info('Ensuring MCA domains are loaded')
    if(!context.getters.mca_domain_list || context.getters.mca_domain_list.length === 0) {
      await context.dispatch('mca_get_domains')
    }
    return context.getters.mca_domain_list
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
