import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import router from "@/synapps/router/router";
import { getToken } from "@/synapps/utils";
import { setupApi } from "@/synapps/api";
import { getI18n } from "@/shared/plugins/i18n";
import layerStore from "@/synapps/store/modules/layerStore";
import plot from "@/synapps/store/modules/plot";
import meetnetStore from "@/shared/store/meetnet";
import multiCriteriaStore from "@/synapps/store/multicriteria";
import { strigiLocationTree } from "../threshold-analysis/actions/dummy-location-tree.mjs";
import axios from "axios";
import { addInterceptorsToAxiosInstance } from "@/shared/services/interceptors";
import { staticModuleConfigs } from "@/synapps/components/modules/static-module.config.mjs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: {
      api_urls: {
        oss: import.meta.env.VITE_OSS_URL,
        ofs: import.meta.env.VITE_OSS_URL,
      },
      opacityLayers: [],
    },
    sharedApps: undefined,
    map: undefined,
    activeModule: undefined,
    language: "nl",
    timeRange: undefined,
    moduleTypes: [
      "staticLayer",
      "scenarios",
      "observations",
      "ais",
      "atlas",
      "multicriteria",
    ],
    observationsModuleTypeOptions: ["sensoren", "tsFallBackAll", "localTimes"],
    controlTypes: ["scale", "toolbox", "coordinates", "search"],
    toolboxControlTypeOptions: [
      "layerInfo",
      "transectTool",
      "areaTool",
      "distanceTool",
    ],
    userData: {},
    token: getToken(),
    siteData: {},
    sensorsWithGroup: {},
    sensorGroups: {},
    echelon: 0,
    echelonWindSpeed: true,
    echelonKp: false,
    showLocationOnMap: false,
    taskBadge: false,
    selectedFeature: undefined
  },
  getters: {
    opacityLayers: (state) => {
      return state.config.opacityLayers;
    },
    isAuthenticated: () => {
      return localStorage.getItem("jwt") !== null;
    },
    sensorsWithGroup: (state) => {
      return state.sensorsWithGroup;
    },
    sensorGroups: (state) => {
      return state.sensorGroups;
    },
    sensoren: (state) => {
      if (
        state.config &&
        state.config.observations &&
        state.config.observations.options &&
        state.config.observations.options.sensoren
      ) {
        return state.config.observations.options.sensoren;
      } else {
        return false;
      }
    },
    tsFallBackAll: (state) => {
      if (
        state.config &&
        state.config.observations &&
        state.config.observations.options &&
        state.config.observations.options.tsFallBackAll
      ) {
        return state.config.observations.options.tsFallBackAll;
      } else {
        return false;
      }
    },
    toolboxControlTypeOptions: (state) => (value) => {
      if (state?.config?.toolbox?.options) {
        if (state?.config?.toolbox?.options[value]) {
          return state.config.toolbox.options[value];
        } else {
          return false;
        }
      } else {
        return state?.config?.controls?.toolbox ? true : false;
      }
    },

    staticModuleConfig(state) {
      return staticModuleConfigs.get(state.activeModule);
    },
    showLocationOnMap: (state) => {
      return state.showLocationOnMap;
    },
  },

  mutations: {
    enableTaskBadge: (state) => {
      state.taskBadge = true;
    },
    disableTaskBadge: (state) => {
      state.taskBadge = false;
    },
    toggleEchelonWindSpeed: (state) =>
      (state.echelonWindSpeed = !state.echelonWindSpeed),
    toggleKp: (state, val) => (state.echelonKp = val),
    setEchelon: (state, val) => (state.echelon += val),
    setShowLocationOnMap: (state, val) => (state.showLocationOnMap = val),
    setOpacity: (state, payload) => {
      if ("opacityLayers" in state.config) {
        let foundLayer = state.config.opacityLayers.find(
          (o) => o.id === payload.id
        );
        if (foundLayer) {
          let indexToDelete = state.config.opacityLayers.findIndex(
            (item) => item.id === payload.id
          );
          state.config.opacityLayers.splice(indexToDelete, 1);

          state.config.opacityLayers.push(payload);
        } else {
          state.config.opacityLayers.push(payload);
        }
      } else {
        state.config.opacityLayers = [payload];
      }
      // state.config.opacityLayers = [];
    },
    setLanguage: (state, payload) => {
      const newLocale = payload.toLowerCase();
      console.info(`Changing locale to '${newLocale}'`);

      // Save the currently selected locale in the store
      state.language = newLocale;

      // Set the locale for vue-i18n so that all $t() translations work
      getI18n().locale = newLocale;
    },
    setSharedApps: (state, payload) => (state.sharedApps = payload),
    setMyApps: (state, payload) => (state.myApps = payload),

    setConfig: (state, config) => {
      if (new URL(location.href).searchParams.has("strigi")) {
        console.warn("Override with debug location tree");
        config.thresholdAnalysis.locations = strigiLocationTree;
      }
      state.config = config;
    },

    setMap: (state, map) => (state.map = map),
    setActiveModule: (state, val) => {
      state.activeModule = state.activeModule == val ? undefined : val;
    },
    setSensorsWithGroup: (state, payload) => {
      state.sensorsWithGroup = payload;
    },
    setSensorGroups: (state, payload) => {
      state.sensorGroups = payload;
    },
    setNonActiveModule: (state, val) => {
      state.activeModule = state.activeModule == val ? undefined : val;
    },
    clearState: (state) => {
      if (state.config.id) {
        console.debug(`Clearing state for app ${state.config.id}`);
        state.config = {};
        state.activeModule = undefined;
      }
    },
    setTimeRange: (state, val) => {
      if (!_.isEqual(state.timeRange, val)) {
        state.timeRange = val;
      }
    },
    setUserData: (state, userData) => (state.userData = userData),
    clearToken: () => {
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      localStorage.clear();
      location.href = `${import.meta.env.VITE_CAS_URL}/logout/?service=${import.meta.env.VITE_CAS_URL}/login/?service=${window.location.href}`;
    },
    setSelectedFeature(state, value) {
      state.selectedFeature = value;
    }
  },
  actions: {
    setShowLocationOnMap: ({ commit }, val) => {
      commit("setShowLocationOnMap", val);
    },
    getConfig: ({ commit, dispatch }, id) => {
      return new Promise(async (resolve) => {
        let api = setupApi(import.meta.env.VITE_CAS_URL + "/api");
        try {
          const { data } = await api.get(`imdc_apps/get_configuration/?id=${id}`);
          
          if (data?.config2?.meetnet) {
            addInterceptorsToAxiosInstance(axios, data.config2.meetnet.url);
            dispatch("reducedSetupMeetnet", {
              ...data.config2.meetnet,
              sinappsAppID: id,
            });
          }
          
          data.config2.id = id;
          commit("setConfig", data.config2);
          resolve(data.config2);

        } catch (err) {
          console.error(err);
          commit("setConfig", {})
          router.push({ name: "appView" });
        }
      });
    },
    async saveConfig({ commit }, config) {
      console.log("saveconfig");

      commit("setConfig", config);
      let api = setupApi(import.meta.env.VITE_CAS_URL);
      await api.post(`/api/imdc_apps/${config.id}/update_config/`, {
        config2: config,
      });
    },

    /*
    Region Site and Site Variables
    */
    getSiteData({ commit, state }, id) {
      new Promise(async (resolve) => {
        let api = setupApi(state.config.api_urls.observations);
        let data;
        try {
          ({ data } = await api.get(`/api/site/${id}/`));
        } catch (err) {
          console.log("error getting site data", err);
        }
        commit("setSiteData", data);
        resolve(data);
      });
    },
    updateSiteData({ commit, state }, payload) {
      new Promise(async (resolve) => {
        let api = setupApi(state.config.api_urls.observations);
        let data;
        let siteID = payload.id;
        delete payload.id;
        try {
          ({ data } = await api.patch(`/api/site/${siteID}/`, payload));
        } catch (err) {
          console.log("error updating site data", err);
        }
        commit("setSiteData", data);
        resolve(data);
      });
    },
    updateSiteVariableData({ state }, payload) {
      new Promise(async (resolve) => {
        let api = setupApi(state.config.api_urls.observations);
        let data;
        let siteVariableID = payload.id;
        delete payload.id;
        try {
          ({ data } = await api.patch(
            `/api/sitevariable/${siteVariableID}/`,
            payload
          ));
        } catch (err) {
          console.log("error updating sitevariable data", err);
        }
        // commit("setSiteData", data);
        resolve(data);
      });
    },

    resetPassword(_context, payload) {
      /*
      payload: Object
        {
          old_password: "",
          new_password: "",
        }
      */
      let token = localStorage.getItem("jwt");
      return axios({
        method: "PUT",
        data: payload,
        headers: { Authorization: `Bearer ${token}` },
        url: `${import.meta.env.VITE_CAS_URL}/change_password`,
      });
    },
  },
  modules: {
    layerStore,
    plot,
    meetnetStore,
    multiCriteriaStore,
  },
});
