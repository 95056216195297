import Vuetify from "vuetify/lib";
import Vue from "vue";
import { getI18n } from '../../shared/plugins/i18n.js';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    // Hooks in vue-i18n into Vuetify, so the translations are managed as a single source of truth by vue-i18n.
    // See https://vuetifyjs.com/en/features/internationalization/#vue-i18n
    t(key, ...params) {
      return getI18n().t(key, params);
    }
  },
  theme: {
    options: { customProperties: true },
  },
});
