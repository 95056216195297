import axios from "axios"

export const config = {
    baseUrl: import.meta.env.VITE_KUSTVISIE_SERVER_URL,
    // axios: null,
}

/**
 * Retrieves the currently logged-in user's permissions from MCA.
 * It's a little strange that this is loaded from an MCA endpoint. Allow me to explain.
 * We're currently (2023-02-01) investigating strategies to generalize the permissions management for different
 * backends under a single umbrella. One options experimented with here is to have the JWT token contain a list of all
 * user permissions, which can then be decoded by the frontend (no need to verify as needed by the backend) to decide
 * what buttons to show and whatnot. When using this strategy, the eventual goal would be to have CAS take care of this.
 * However, since modifying CAS is currently way too complex to do within the timewindow available for the MCA project
 * we decided to "fake it", by giving MCA backend and endpoint like CAS has that decodes the JWT from CAS, but also
 * augment it with extra stuff.
 * If this is a successful experiment, we'd want to move up this behaviour into CAS, and use the CAS endpoint for this,
 * or even not use any endpoint as we can just decode (not verify) the JWT locally thus providing all permissions
 * information we need to show/hide stuff on the frontend. Ofcourse, the backend itself should always fully verify the
 * JWT, but the frontend does not have to.
 * So, in time the endpoint {{mca}}/api/api-token-verify might become {{cas}}/api/api-token-verify
 */
export async function fetchUserInfo() {
    try {
        console.info('Fetching MCA user info')
        const response = await axios.post(`${config.baseUrl}api-token-verify/`)
        const user = createUser(response)
        console.debug(`Fetched user info %s`, user)
        return user;

    } catch(error) {
        throw new Error(`Unable to fetch MCA user info due to '${error.message}'`, { cause: error })
    }
}

/**
 * Creates a richer domain type object from the response.
 * @param response Axios HTTP response.
 * @returns {object}
 */
function createUser(response) {
    const user = Object.create({
        hasPermission: function (permission) {
            if (typeof permission !== 'string') {
                throw new Error(`Argument 'permission' must be a string`)
            }

            const allowed = this.permissions.includes(permission)
            if (allowed) {
                console.debug(`User '${this.email}' is allowed to '${permission}'`)
            } else {
                console.debug(`User '${this.email}' is disallowed to '${permission}'`)
            }

            return allowed
        },

        get [Symbol.toStringTag]() {
            return `User{username='${this.username}', email='${this.email}' first_name='${this.first_name}', last_name='${this.last_name}', permissions.length=${this.permissions.length}}`
        }
    })

    Object.assign(user, response.data)
    return user;
}

export default {
    config,
    fetchUserInfo,
}
