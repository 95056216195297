<template>
  <v-app id="v-app">
    <Keypress key-event="keyup" :key-code="27" @success="closeModalGlobal"></Keypress>
    <div class="topBar" style="margin-bottom: 48px">
      <topBar app></topBar>
    </div>

    <vue-progress-bar></vue-progress-bar>
    <transition name="fade" mode="out-in">
      <router-view :key="$router.fullpath"></router-view>
    </transition>
    <!-- <shortcuts></shortcuts> -->
  </v-app>
</template>


<script>
import { eventBus } from "./main";
import { mapMutations } from "vuex";
import { getUserData } from "@/synapps/api";
import shortcuts from "@/synapps/components/shortcuts";
import topBar from "@/synapps/components/topBar";

export default {
  components: {
    topBar,
    shortcuts,
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["setUserData"]),
    closeModalGlobal() {
      eventBus.$emit("closeModalGlobal");
    },
  },
  created() {
    try {
      this.setUserData(getUserData());
    } catch (err) {
      console.error(err)
    }
  },
  mounted() {
    this.$set_branding_info();
  }
};
</script>

<style>
html,
body,
#app,
/* #v-app {
  height: 100%;
} */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#v-app .v-dialog .v-card {
  margin: 0;
}

#v-app .alertify {
  z-index: 200000000;
}
</style>