import axios from "axios";
import router from "@/synapps/router/router";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import moment from "moment";
import {getToken} from "./utils.js";

var token = localStorage.getItem("jwt") || null;

function verify(token) {
    const verifyUrl = `${import.meta.env.VITE_CAS_URL}/api-token-verify/`
    console.info(`Verifying authentication token '${token}' through '${verifyUrl}'`)
    return axios.post(verifyUrl, {token});
}

async function refresh(token) {
    try {
        let {
            data: {token: newToken},
        } = await axios.post(`${import.meta.env.VITE_CAS_URL}/api-token-refresh/`, {
            token,
        });
        localStorage.setItem("jwt", newToken);
        let {exp} = jwt_decode(newToken);
        let timeout = moment().diff(
            moment(exp * 1000).subtract(30, "seconds"),
            "seconds"
        );
        setTimeout(() => refresh(newToken), Math.abs(timeout) * 1000);
    } catch (err) {
        router.push(
            `${import.meta.env.VITE_CAS_URL}/login?service=${window.location.href}`
        );
    }
}

export function getUserData() {
    let res = jwt_decode(getToken());
    return res.user;
}

/**
 * Given a userData object, check if the user has a certain named permission.
 * @param {string} permission - Name of the permission to check.
 * @param {object} userData - userData object; see also getUserData.
 */
export function userHasPermission(permission, userData) {
    return Array.isArray(userData?.permissions) ? userData.permissions.includes(permission) : false
}

export function setupApi(url) {
    let instance = axios.create({
        baseURL: url,
        headers:
            _.includes(url, "imdcapps") || _.includes(url, "localhost")
                ? {
                    authorization: `Bearer ${getToken()}`,
                }
                : {},
    });
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (401 === error.response?.status) {
                console.log(window.location.href);
                // if (_.includes(window.location.href, "localhost")) {
                //   alertify.prompt("Insert jwt token", (val) => {
                //     document.cookie = `jwt_synapps=${val}`;
                //     window.location.reload();
                //   });
                // } else {
                //   alertify.error(
                //     "You seem to have incorrect authorization credentials. We will redirect you to the login page in 3 seconds."
                //   );
                //   setTimeout(() => {
                //     window.location.replace(import.meta.env.VITE_CAS_URL + "/logout");
                //     return Promise.reject(error);
                //   }, 3000);
                // }
            } else {
                return Promise.reject(error);
            }
        }
    );

    return instance;
}

function toLogin() {
    const loginUrl = `${import.meta.env.VITE_CAS_URL}/login?service=${window.location.origin}`
    console.info(`Redirecting browser to CAS login at '${loginUrl}'`)
    window.location.replace(loginUrl);
}

export function waitForAuth() {
    return new Promise(async (resolve) => {
        console.info('Checking for user authentication')
        if (token) {
            console.info(`Authentication token found in localStorage key 'jwt'`)
            try {
                await verify(token);
                console.info(`Authentication token verified`)
            } catch (error) {
                console.info(`Failed to verify authentication token! Clearing existing token and relaying to CAS.`)
                console.error(error);
                localStorage.removeItem("jwt");
                toLogin();
            }
        } else {
            console.info(`Unable to find authentication token in localstorage key 'jwt'; Relaying to CAS.`)
            let url = new URL(window.location.href);
            window.history.replaceState(null, null, window.location.pathname);

            let ticket = url.searchParams.get("ticket");
            if (ticket) {
                try {
                    let {data: resp} = await axios.get(
                        `${import.meta.env.VITE_CAS_URL}/serviceValidate?ticket=${ticket}&service=${window.location.origin}`
                    );
                    let parser = new DOMParser();
                    resp = parser.parseFromString(resp, "text/xml");
                    token = resp.getElementsByTagName("cas:jwt_synapps")[0].innerHTML;
                    localStorage.setItem("jwt", token);
                } catch (error) {
                    console.log(error)
                    window.location.replace(`${import.meta.env.VITE_CAS_URL}/logout`);
                }
            } else {
                toLogin();
            }
        }

        if (token) {
            const {exp} = jwt_decode(token);
            const timeout = moment().diff(
                moment(exp * 1000).subtract(30, "seconds"),
                "seconds"
            );

            setTimeout(() => {
                refresh(token);
            }, Math.abs(timeout) * 1000);
            resolve();
        }
    });
}
